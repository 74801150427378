body {
  background-color: #333;
  color: #fff;
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 50%;
  margin: 20px 0;
}

.input-box {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px 0;
}

.input-box input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #444;
  color: #fff;
  margin: 0 10px 10px 0;
}

.input-box input:last-child {
  margin-right: 0;
}

.button {
  padding: 10px 20px;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a {
  color: #5fd4f4;
}

.error {
  color: #EEBABA;
}

.clipboard-btn {
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
}

.footer {
  font-size: 10px;
  padding-top: 50px;
  color: grey;
}